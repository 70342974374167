// superbol/frontend/superbolweb/src/components/modules/languageData.js

const texts = {
    en: {
      // Menu Bar
      home: "HOME",
      about: "ABOUT",
      location: "LOCATION",
      menu: "MENU",
      orderOnline: "ORDER",
      languageLabel: "FRANÇAIS",
      // Order Modal Title
      ordermodalTitle: "CHOOSE YOUR FOOD ORDER SERVICE",
      // Homepage - Section 1
      typeAnimationText: [
        'WELCOME TO SUPERBOL', 1000,
        'WELCOME TO SUPER GRILLED CHICKEN', 1000,
        'WELCOME TO SUPER GRILLED SHRIMP', 1000,
        'WELCOME TO SUPER FOOD', 1000,
      ],
      // Homepage - Section 2
      section2: "Check out our delicious offerings!",
      // Homepage - Section 3
      section3: "Order from our partners",
      // About Page
      aboutPage: {
        title: "About Us",
        description: "Learn more about our story and values.",
        storyTitle: "Our Story",
        storyContent: `SuperBol was born from the dreams and dedication of three visionaries with diverse backgrounds, united by a common love for the fast food industry. One brought a wealth of knowledge from the restaurant and real estate sectors, another was already a co-owner of three successful restaurants, and the third came from a management consulting and process improvement industry. Together, they created SuperBol, a place where every dish tells a story of flavor and innovation.`,
        visionTitle: "Our Vision",
        visionContent: "At SuperBol, we believe that fast food can be both delicious and nutritious. Our menu showcases a range of bowls, poutines, and sides that cater to diverse tastes and preferences. Join us and be part of our story, where every bite is an adventure."
      },
    },
    fr: {
      // Menu Bar
      home: "ACCUEIL",
      about: "À PROPOS",
      location: "EMPLACEMENT",
      menu: "MENU",
      orderOnline: "COMMANDER",
      languageLabel: "ENGLISH",
      // Order Modal Title
      ordermodalTitle: "CHOISISSEZ VOTRE SERVICE DE COMMANDE",
      // Homepage - Section 1
      typeAnimationText: [
        'BIENVENUE À SUPERBOL', 1000,
        'BIENVENUE AU SUPER POULET GRILLÉ', 1000,
        'BIENVENUE AU SUPER CREVETTES GRILLÉES', 1000,
        'BIENVENUE AU SUPER MENU', 1000,
      ],
      // Homepage - Section 2
      section2: "Découvrez nos délicieuses offres !",
      // Homepage - Section 3
      section3: "Commander à partir de nos partenaires",
      // About Page
      aboutPage: {
        title: "À Propos",
        description: "Apprenez-en plus sur notre histoire et nos valeurs.",
        storyTitle: "Notre Histoire",
        storyContent: `SuperBol est né des rêves et de la détermination de trois visionnaires aux parcours diversifiés, unis par un amour commun pour l'industrie de la restauration rapide. L'un apportait une vaste connaissance des secteurs de la restauration et de l'immobilier, un autre était déjà copropriétaire de trois restaurants à succès, et le troisième venait du consultation d'affaires et de l'amélioration continue. Ensemble, ils ont créé SuperBol, un lieu où chaque plat raconte une histoire de saveur et d'innovation.`,
        visionTitle: "Notre Vision",
        visionContent: "Chez SuperBol, nous croyons que la restauration rapide peut être à la fois délicieuse et nutritive. Notre menu présente une gamme de bols, poutines et accompagnements qui répondent aux goûts et préférences variés. Rejoignez-nous et faites partie de notre histoire, où chaque bouchée est une aventure."
      },
    }
};

  export default texts;

