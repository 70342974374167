// superbol/frontend/superbolweb/src/components/modules/Footer.js

import React from 'react';
import { Box, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import logoImage from '../../../src/components/media/images/logo/SuperBol_Background_Transparent_Logo.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

function Footer() {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px', // Reduced padding to decrease footer height
      backgroundColor: 'background.paper',
      borderTop: '1px solid',
      borderColor: 'divider',
      height: '70px', // Explicit height reduction
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src={logoImage} alt="SuperBol Logo" style={{ height: '60px', marginRight: '10px' }} /> {/* Adjusted logo size */}
        <Typography variant="body2" color="text.secondary">
          © SuperBol 2024
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <MuiLink href="https://www.facebook.com/YourPage" color="inherit" sx={{ marginRight: '10px', textDecoration: 'none' }}>
          <FacebookIcon />
        </MuiLink>
        <MuiLink href="https://www.instagram.com/YourPage" color="inherit" sx={{ textDecoration: 'none' }}>
          <InstagramIcon />
        </MuiLink>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          <MuiLink component={Link} to="/pagemap" color="inherit" sx={{ marginRight: '10px', textDecoration: 'none' }}>
            pagemap
          </MuiLink>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <MuiLink href="/sitemap.xml" color="inherit" sx={{ textDecoration: 'none' }}>
            sitemap
          </MuiLink>
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;

