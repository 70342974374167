// superbol/frontend/superbolweb/src/App.js

import React, { useState, createContext, lazy, Suspense } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { lightTheme, darkTheme } from './components/modules/theme';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// Other Components
import texts from './components/modules/languageData';
import MenuBar from './components/modules/MenuBar';
import Footer from './components/modules/Footer';

// Styles
import './App.css';

// Lazy Loaded Pages
const Home = lazy(() => import('./components/pages/Home'));
const About = lazy(() => import('./components/pages/About'));
const Menu = lazy(() => import('./components/pages/Menu'));
const Order = lazy(() => import('./components/pages/Order'));
const Contact = lazy(() => import('./components/pages/Contact'));
const PageMap = lazy(() => import('./components/modules/PageMap'));

// Error pages
const Error404 = lazy(() => import('./components/pages/errors/Error404'));
const Error500 = lazy(() => import('./components/pages/errors/Error500'));

export const AppContext = createContext();

function App() {
  const [language, setLanguage] = useState('en');
  const [theme, setTheme] = useState('light');

  const toggleLanguage = () => {
    setLanguage(lang => (lang === 'en' ? 'fr' : 'en'));
  };

  const toggleTheme = () => {
    setTheme(current => (current === 'light' ? 'dark' : 'light'));
  };

  const langTexts = texts[language] || texts.en; // Fallback to English texts if language is not found
  const currentTheme = theme === 'light' ? lightTheme : darkTheme;

  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  return (
    <HelmetProvider>
      <AppContext.Provider value={{ toggleTheme, toggleLanguage, langTexts, language, theme }}>
        <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
          <ThemeProvider theme={currentTheme}>
            <CssBaseline />
            <Router>
              <MenuBar />
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/menu" element={<Menu />} />
                  <Route path="/order" element={<Order />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/pagemap" element={<PageMap />} />
                  <Route path="/error/404" element={<Error404 />} />
                  <Route path="/error/500" element={<Error500 />} />
                  <Route path="*" element={<Error404 />} />
                </Routes>
              </Suspense>
              <Footer />
            </Router>
          </ThemeProvider>
        </GoogleReCaptchaProvider>
      </AppContext.Provider>
    </HelmetProvider>
  );
}

export default App;

