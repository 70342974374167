// superbol/frontend/superbolweb/src/components/modules/MenuBar.js

import React, { useState, useContext } from 'react';
import { AppContext } from '../../App';
import { Link as RouterLink } from 'react-router-dom';
import {
  Breadcrumbs, IconButton, Switch, Drawer, Grid, Dialog, DialogContent,
  List, ListItem, FormControlLabel, useMediaQuery, useTheme, Link
} from '@mui/material';
import { styled } from '@mui/system';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import { FaCanadianMapleLeaf } from "react-icons/fa";
import logoImage from '../../../src/components/media/images/logo/SuperBol_Background_Transparent_Logo.png';
import sloganImage from '../../../src/components/media/images/logo/SuperBol_Menbar_Slogan.png';

// Import the icons
import doordashIcon from '../../components/media/icons/doordash.png';
import livroIcon from '../../components/media/icons/livro.png';
import ubereatsIcon from '../../components/media/icons/ubereats.png';
import ueatIcon from '../../components/media/icons/ueat.png';

const CustomLink = styled(RouterLink)(({ theme }) => ({
  textDecoration: 'none',
  fontFamily: '"Bebas Neue", sans-serif',
  fontSize: '30px',
  fontWeight: 700,
  textTransform: 'uppercase',
  letterSpacing: '0px',
  color: theme.palette.text.primary,
}));

const AnimatedLink = styled(CustomLink)(({ theme }) => ({
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
    textDecoration: 'none',
  },
  '&:active': {
    transform: 'scale(0.95)',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
  },
}));

const LanguageToggle = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.text.primary,
  marginLeft: '5px',
  verticalAlign: 'middle',
  fontSize: '16px',
  fontWeight: 700,
  textDecoration: 'underline',
}));

function MenuBar() {
  const { toggleTheme, toggleLanguage, langTexts, theme } = useContext(AppContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const themeContext = useTheme();
  const isMobileView = useMediaQuery(themeContext.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  if (!langTexts) {
    return null;
  }

  return (
    <nav className="menubar" style={{ backgroundColor: 'transparent', position: 'absolute', top: 0, width: '100%', zIndex: 1000 }}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleDrawerToggle}
        className="mobile-menu-button"
        style={{ position: 'absolute', top: '10px', right: '10px' }}
      >
        <MenuIcon />
      </IconButton>
      {isMobileView && (
        <div style={{ position: 'absolute', left: '50%', top: '10px', transform: 'translateX(-50%)' }}>
          <img src={logoImage} alt="SuperBol Logo" className="mobile-logo" />
        </div>
      )}
      {!isMobileView && (
        <>
          <Breadcrumbs separator="|" aria-label="breadcrumb" maxItems={10} style={{ justifyContent: 'center', display: 'flex', flexGrow: 1 }}>
            <AnimatedLink to="/">{langTexts.home}</AnimatedLink>
            <AnimatedLink to="/about">{langTexts.about}</AnimatedLink>
            <AnimatedLink to="/menu">{langTexts.menu}</AnimatedLink>
            <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={logoImage} alt="SuperBol Logo" className="desktop-logo" onClick={toggleModal} />
            </div>
            <AnimatedLink to="/order">{langTexts.orderOnline}</AnimatedLink>
            <IconButton to="/contact" component={RouterLink} style={{ color: 'inherit', marginLeft: '10px' }}>
              <EmailIcon />
            </IconButton>
            <div>
              <Switch
                checked={theme === 'dark'}
                onChange={toggleTheme}
                icon={<Brightness7Icon sx={{ color: '#FFC107' }} />}
                checkedIcon={<Brightness4Icon />}
                inputProps={{ id: 'theme-switch', name: 'theme-switch' }}
              />
              <FaCanadianMapleLeaf size={30} onClick={toggleLanguage} style={{ cursor: 'pointer' }} />
              <LanguageToggle onClick={toggleLanguage}>{langTexts.languageLabel}</LanguageToggle>
            </div>
          </Breadcrumbs>
          <div className="logo-container">
            <img src={sloganImage} alt="SuperBol Slogan" className="slogan-logo" />
          </div>
        </>
      )}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 } }}
      >
        <List>
          <ListItem><AnimatedLink to="/" onClick={handleDrawerToggle}>{langTexts.home}</AnimatedLink></ListItem>
          <ListItem><AnimatedLink to="/about" onClick={handleDrawerToggle}>{langTexts.about}</AnimatedLink></ListItem>
          <ListItem><AnimatedLink to="/menu" onClick={handleDrawerToggle}>{langTexts.menu}</AnimatedLink></ListItem>
          <ListItem><AnimatedLink to="/order" onClick={handleDrawerToggle}>{langTexts.orderOnline}</AnimatedLink></ListItem>
          <IconButton to="/contact" component={RouterLink} style={{ color: 'inherit', marginLeft: '10px' }}>
            <EmailIcon />
          </IconButton>
          <ListItem>
            <FormControlLabel
              control={
                <Switch
                  checked={theme === 'dark'}
                  onChange={toggleTheme}
                  icon={<Brightness7Icon />}
                  checkedIcon={<Brightness4Icon />}
                />
              }
            />
          </ListItem>
          <ListItem>
            <FaCanadianMapleLeaf size={30} onClick={toggleLanguage} style={{ cursor: 'pointer' }} />
            <LanguageToggle onClick={toggleLanguage}>{langTexts.languageLabel}</LanguageToggle>
          </ListItem>
        </List>
      </Drawer>
      <Dialog open={modalOpen} onClose={toggleModal} PaperProps={{ elevation: 8 }}>
        <IconButton onClick={toggleModal} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={2} style={{ padding: '20px', justifyContent: 'center' }}>
            <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '20px' }}>
              <h2>{langTexts.ordermodalTitle}</h2>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Link href="https://www.doordash.com" target="_blank" rel="noopener noreferrer">
                <img src={doordashIcon} alt="DoorDash" style={{ width: '100px', margin: '0 10px' }} />
              </Link>
              <Link href="https://www.livro.com" target="_blank" rel="noopener noreferrer">
                <img src={livroIcon} alt="Livro" style={{ width: '100px', margin: '0 10px' }} />
              </Link>
              <Link href="https://www.ubereats.com" target="_blank" rel="noopener noreferrer">
                <img src={ubereatsIcon} alt="UberEats" style={{ width: '100px', margin: '0 10px' }} />
              </Link>
              <Link href="https://www.ueat.io" target="_blank" rel="noopener noreferrer">
                <img src={ueatIcon} alt="UEat" style={{ width: '100px', margin: '0 10px' }} />
              </Link>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </nav>
  );
}

export default MenuBar;

